<template>
  <v-dialog v-model="showModal" width="700px">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>Invoice edit</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="showModal=false"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>
      <v-progress-circular indeterminate v-if="invoiceLoading"/>
      <div v-if="invoice" class="px-10">
        <v-simple-table>
          <thead>
          <tr>
            <th>Nr</th>
            <th>Product</th>
            <th>Price</th>
            <th>Qty</th>
            <th>Total</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, itemK) in invoice.items" :key="item.id">
            <td>{{ itemK + 1 }}.</td>
            <td>
              {{item.product.name }}
              <div>
                <v-textarea outlined v-model="item.description" />
              </div>
            </td>
            <td>${{ item.price.toFixed(2) }}</td>
            <td>{{ item.quantity }}</td>
            <td>${{ (item.price*item.quantity).toFixed(2) }}</td>
          </tr>
          </tbody>
        </v-simple-table>
        <v-btn color="green" class="my-5" dark @click="saveInvoice">Save</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import globals from "../mixins/globals";

export default {
  name: "InvoicePositions",
  props: ['value','invoiceId'],
  mixins: [globals],
  data () {
    return {
      showModal: this.$props.value,
      invoice: null,
      invoiceLoading: null
    }
  },
  watch: {
    showModal (val) {
      this.$emit('input', val);
    },
    '$props.value': {
      handler (val) {
        this.showModal = val;
        if(val) this.loadInvoice();
      }
    }
  },
  methods: {
    saveInvoice () {
      this.invoiceLoading = true;
      axios
          .post(this.globals.apiUrl + "/invoices/patch/" + this.invoiceId,{
            invoice: this.invoice
          })
          .then((res) => {
            this.invoice = res.data
          })
          .finally(() => {
            this.invoiceLoading = false;
          });
      this.invoice = null;
    },
    loadInvoice () {
      this.invoice = null;
      this.invoiceLoading = true;
      axios
          .post(this.globals.apiUrl + "/invoices/get/" + this.invoiceId)
          .then((res) => {
            this.invoice = res.data
          })
          .finally(() => {
            this.invoiceLoading = false;
          });
    }
  }
}
</script>

<style scoped>

</style>
